import { useLang } from "hooks/useLang"

interface IProps {
    price: number
    currency?: string
    className?: string
}

const PriceComponent = ({ currency, price, className }: IProps) => {
    const { t } = useLang()
    return (
        <div className={className}>
            {price === 0 ? (
                <span>{t("free_tickets")}</span>
            ) : (
                <span>{price} </span>
            )}
            {price > 0 ? (
                <span className="font-sans">
                    {currency ? currency : t("SAR")}
                </span>
            ) : null}
        </div>
    )
}
export default PriceComponent
