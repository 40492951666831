import Image, { ImageProps } from "next/image"
import React, { useState, useEffect } from "react"

const CustomImage = ({ src, alt, ...props }: ImageProps) => {
    const fallbackImage = "/svg/v2/pass_ticket_fallback_image_light.png"
    const [imgSrc, setImgSrc] = useState(src || fallbackImage)

    useEffect(() => {
        setImgSrc(src || fallbackImage)
    }, [src])

    return (
        <Image
            src={imgSrc}
            alt={alt || "fallback-image"}
            onError={() => {
                setImgSrc(fallbackImage)
            }}
            {...props}
        />
    )
}

export default CustomImage
