import dayjs from "dayjs"
import locale_ar from "dayjs/locale/ar-sa"
import locale_en from "dayjs/locale/en"
import { useLang } from "hooks/useLang"
interface IProps {
    date: string | Date
    className?: string
}

const DataDisplay = ({ date, className }: IProps) => {
    const { isAr } = useLang()
    return (
        <span className={className}>
            {dayjs(date)
                .locale(isAr ? locale_ar : locale_en)
                .format("D MMMM")}
        </span>
    )
}
export default DataDisplay
