import { useLang } from "hooks/useLang"
import useWindowSize from "hooks/useWindowSize"
import dynamic from "next/dynamic"
import React, { Fragment, ReactNode } from "react"

const LoadingImage = dynamic(
    () => import("components/atoms/Image/LoadingImage"),
    {
        ssr: false,
    }
)

interface IProps {
    count?: number
    type: "event_card" | "small_card" | "hero_image" | "event_card_empty"
    title?: boolean
    msg?: string
    className: string
    children?: ReactNode
}

const Skeleton = ({
    type,
    count,
    title,
    className: customClassName,
    children,
    msg,
}: IProps) => {
    const { width } = useWindowSize()
    const { t } = useLang()
    const dynamicCount =
        width > 1280 ? 4 : width > 1024 ? 3 : width > 640 ? 2 : 1

    const skeletonType = () => {
        switch (type) {
            case "event_card":
                return (
                    <div className="flex h-[490px] w-full animate-pulse flex-col overflow-hidden rounded-[20px] bg-transparent dark:bg-transparent">
                        <div className="flex h-[235px] w-full items-center justify-center bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                        <div className="flex flex-1 flex-col gap-5 pt-5">
                            <div className="h-[30px] w-full rounded-md bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                            <div className="h-[30px] w-full rounded-md bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                            <hr className="dark:border-[#33276F]" />
                            <div className="h-[46px] w-full rounded-md bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                            <div className="h-[30px] w-full rounded-md bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                        </div>
                    </div>
                )
            case "small_card":
                return (
                    <div className="relative flex h-full w-full animate-pulse items-center justify-center overflow-hidden rounded-[20px] bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                )
            case "hero_image":
                return (
                    <div className="relative flex h-full w-full items-center justify-center bg-gray-400 dark:bg-[#33276F]">
                        <LoadingImage />
                    </div>
                )
            case "event_card_empty":
                return (
                    <div className="flex h-[490px] w-full flex-col overflow-hidden rounded-[20px] bg-transparent dark:bg-transparent">
                        <div className="flex h-[235px] w-full items-center justify-center bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                        <div className="flex flex-1 flex-col gap-5 pt-5">
                            <div className="h-[30px] w-full rounded-md bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                            <div className="h-[30px] w-full rounded-md bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                            <hr className="dark:border-[#33276F]" />
                            <div className="h-[46px] w-full rounded-md bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                            <div className="h-[30px] w-full rounded-md bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <div className="relative flex flex-col gap-5">
            {title && (
                <div className="flex animate-pulse flex-col gap-2">
                    <div className="h-7 w-[150px] rounded-full bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                    <div className="h-2.5 w-[300px] rounded-full bg-[#F7F6FF] dark:bg-[#33276F]"></div>
                </div>
            )}

            <div className={customClassName}>
                {Array.from(
                    { length: count ? count : dynamicCount },
                    (_, i) => i + 1
                ).map((e) => (
                    <Fragment key={e}>{skeletonType()}</Fragment>
                ))}
            </div>

            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-base md:text-xl">
                {children ||
                    (type === "event_card_empty" &&
                        (msg || t("no_events_yet")))}
            </div>
        </div>
    )
}

export default Skeleton
