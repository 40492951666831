import { AvailableTicket, IFinalEvent } from "types/event"

//TODO: improve this function by just proessing the tickets not the whole event.
//TODO: maybe extract to a custom hook.
export const getLowestTicketPrice = (event: IFinalEvent) => {
    if (event?.availableTickets) {
        const x = event?.availableTickets?.sort((a: any, b: any) =>
            a.discount.previous_value
                ? a?.discount.current_value - b?.discount.current_value
                : a.price - b.price
        )

        return x[0]
    }
}

export const hasMultiplePrices = (event: IFinalEvent) => {
    if (event?.availableTickets && event?.availableTickets?.length > 1) {
        // check if all tickets have the same price
        const prices = event?.availableTickets?.map(
            (ticket: AvailableTicket) => ticket.price
        ) as number[]

        return new Set(prices).size !== 1
    }

    return false
}
