import { useLang } from "hooks/useLang"
import { IFinalEvent } from "types/event"
import DataDisplay from "./atoms/DateDisplay"
import PriceComponent from "./atoms/PriceComponent"
import { FiMapPin, FiCalendar } from "react-icons/fi"
import { sortPrice } from "helpers/sortEventPrice"
import Button from "./Form/Button"
import CustomImage from "./atoms/Image/CustomImage"
import truncateString from "helpers/truncateString"
import { hasMultiplePrices } from "helpers/eventPrice"
import Link from "next/link"

interface EventCardProps {
    event: IFinalEvent
    className?: string
    isOrgPage?: boolean
    priorityImage?: boolean
}

const NewEventCard = ({ ...props }: EventCardProps) => {
    const { multiLangVars, t, isAr } = useLang()

    const { event, isOrgPage, priorityImage } = props

    const cardImg = event?.posters?.[0]
        ? `${process.env.NEXT_PUBLIC_Event_Img_URL}${event.posters[0]}`
        : "/svg/v2/pass_ticket_fallback_image_light.png"

    const price = sortPrice(event)

    const isEventExpired = () => {
        const today = new Date()
        const eventDate = new Date(event?.event_date?.ending_date)
        return today > eventDate
    }

    function everyFunc(el: { end_slot: string | number | Date }) {
        const today = new Date()
        return today > new Date(el.end_slot)
    }

    const isTimeSlotsExpired = event?.time_slots?.every(everyFunc)

    const disableBtn = () => {
        if (event?.availableTickets?.length === 0) {
            return true
        }
        if (event?.time_slots?.length === 0) {
            return true
        }
        if (!isEventExpired() && isTimeSlotsExpired) {
            return true
        }
        return false
    }

    const isMultiplePrices = hasMultiplePrices(event)

    const eventTitle =
        event?.[multiLangVars("title") as "en_title" | "ar_title"] || ""

    const isBookingEnabled = event?.booking?.is_enable_booking !== false

    const getButtonLabel = () => {
        if (isBookingEnabled) {
            return t("book_now")
        }
        return isAr
            ? event.booking?.ar_message || t("not_available")
            : event.booking?.en_message || t("not_available")
    }

    const eventLink =
        event?._id === "672cdbeca294b9a80a037b17" ||
        event?._id === "6343f62744f557215447e976"
            ? `/events/guvali`
            : `/events/${event?._id}`

    return (
        <Link
            href={eventLink}
            className="flex w-full cursor-pointer flex-col overflow-hidden rounded-[14px] shadow-lg transition-all dark:shadow-none md:hover:shadow-2xl"
        >
            <div className="relative aspect-video">
                <CustomImage
                    src={cardImg}
                    alt={eventTitle}
                    fill
                    priority={priorityImage ? priorityImage : false}
                    className="object-cover"
                />
            </div>

            <div className="flex flex-1 flex-col justify-between gap-y-6 p-4 bg-white">
                <div className="flex flex-col gap-4">
                    <div className="self-start font-bold text-black hover:underline md:text-base">
                        {truncateString(eventTitle, 28)}
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-2">
                            <FiMapPin color="#FF3D85" />
                            <div>
                                <span className="text-sm text-black">
                                    {t(event?.address?.city || "")}
                                </span>
                                {event?.address?.country ? (
                                    <span className="text-sm text-black">
                                        , {t(event?.address?.country)}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <FiCalendar color="#FF3D85" />
                            <div className="flex items-center gap-1 text-sm text-black">
                                <span>{t("starting_from")}</span>
                                <DataDisplay
                                    date={
                                        event?.event_date?.starting_date ?? ""
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-between gap-y-4">
                    <div className="flex flex-wrap items-end justify-between gap-y-1">
                        {isOrgPage ? null : disableBtn() ? null : (
                            <div className="flex flex-col gap-0">
                                <span className="text-[9px] font-normal text-primary-theme-400 md:text-[10px]">
                                    {isMultiplePrices
                                        ? t("starting_from")
                                        : t("Ticket_price")}
                                </span>
                                <div className="flex items-end gap-2">
                                    <PriceComponent
                                        price={
                                            price?.discount.previous_value
                                                ? price?.discount.current_value
                                                : price?.price
                                        }
                                        className="flex flex-row items-center justify-center gap-1 text-base font-bold text-primary-theme-400"
                                    />
                                    {price?.discount.previous_value !== 0 && (
                                        <PriceComponent
                                            price={
                                                price?.discount.previous_value
                                            }
                                            className="flex flex-row items-center justify-center gap-1 text-xs font-bold text-gray-500 line-through"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {isOrgPage ? null : disableBtn() ? (
                            <Button
                                disabled
                                className="w-full rounded-full px-4 py-1 text-sm font-bold capitalize text-white transition-all md:px-6"
                            >
                                {t("no_tickets_available")}
                            </Button>
                        ) : (
                            <Button
                                disabled={!isBookingEnabled}
                                className="rounded-full px-4 py-1 text-sm font-bold capitalize text-white transition-all md:px-6"
                            >
                                {getButtonLabel()}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default NewEventCard
