export const sortPrice = (list: any) => {
    if (list?.availableTickets) {
        const x = list?.availableTickets?.sort((a: any, b: any) =>
            a?.discount?.previous_value
                ? a?.discount?.current_value - b?.discount?.current_value
                : a?.price - b?.price
        )

        return x[0]
    }
}
